$width: 1200px;
$outerWidth: 1366px;

$tablet: 'all and (max-width: #{$width})';
$mobile: 'all and (max-width: 768px)';
@import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic');

body{
  font-family: 'Rubik', sans-serif;
}

*{
  box-sizing: border-box;
  outline: none!important;
}
.container {
  width: 100%;
  max-width: $outerWidth;
  margin: 0 auto;
  .inner {
    width: 100%;
    max-width: $width;
    margin: 0 auto;
    //@media(max-width: 1300px){
    //  max-width: 100%;
    //}

  }
}

.align {
  &--right {
    text-align: right;
  }
  &--left {
    text-align: left;
  }
  &--center{
    text-align: center;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  height: auto;
  width: auto;
  max-width: 100%;
}



$col: 12;
@for $i from 1 through $col {
  .col-#{$i} {
    width: 100%*$i/$col;
    float: left;
  }
  .ml-#{$i} {
    margin-left: 100%*$i/$col;
  }
  .mr-#{$i} {
    margin-right: 100%*$i/$col;
  }
}

//footer fix
* {
  margin: 0;
  padding: 0;
}
html, body {
  height: 100%;
}
.site_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  //overflow-x: hidden;
}
.main{
  overflow-x: hidden;
  overflow: hidden;
  flex: 1 0 auto;
  width: 100%;
}
.footer {
  flex: 0 0 auto;
}
//footer fix
.table-wrapper{
  display: table;
  width: 100%;
  min-height: 100%;
  height: 1px;
}
.cell-wrapper{
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.flex-wrapper{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}
a,button{
  transition-duration: 0.3s;
}

a.db{
  display: inline-block;
  vertical-align: top;
}
.btn{
  background: transparent;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  //def
  padding: 0 40px;
  line-height: 44px;
  font-size: 12px;
  cursor: pointer;
  overflow: hidden;
  &-white{
    border: 2px solid #fff;
    color: #fff;
    position: relative;
    font-weight: 700;
    letter-spacing: 1px;
    &:hover{
      //background: #fff;
      //color: #000000;
    }
    &:before{
      content: url("../img/btn-arrow-white.png");
      position: absolute;
      right: 15px;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition-duration: 0.3s;
    }
    &:after{
      width: 94px;
      height: 94px;
      background-color: #ffffff;
      position: absolute;
      top: -26px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      transition-duration: 0.1s;
      content: '';
      display: block;
      border-radius: 50%;
      opacity: 0;
    }
    &:hover{
      //background: darken(#3acc8a,15%);
      &:before{
        opacity: 1;
      }
    }
    &:active{
      &:after{
        opacity: 0.4;
        width: 100%;
      }
    }

  }
  &-green{
    border: 2px solid #3acc8a;
    color: #3acc8a;
    position: relative;
    letter-spacing: 1px;
    font-weight: 700;
    //&:hover{
    //  background: #3acc8a;
    //  color: #fff;
    //}

    &:before{
      content: url("../img/btn-arrow-green.png");
      position: absolute;
      right: 15px;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition-duration: 0.3s;
    }
    &:after{
      width: 94px;
      height: 94px;
      background-color: #3acc8a;
      position: absolute;
      top: -26px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      transition-duration: 0.1s;
      content: '';
      display: block;
      border-radius: 50%;
      opacity: 0;
    }
    &:hover{
      //background: darken(#3acc8a,15%);
      &:before{
        opacity: 1;
      }
    }
    &:active{
      &:after{
        opacity: 0.4;
        width: 100%;
      }
    }

  }

}
