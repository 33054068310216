.footer{
  background: #e5e5e5;
  padding: 30px 0 70px;
  @media(max-width: 767px){
    padding-bottom: 30px;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 300;
    li{
      line-height: 1.4;
    }
  }
  .flex-wrapper{
    justify-content: center;
  }
  &--item{
    padding: 0 60px;
    @media(max-width: 767px){
      padding: 0 30px;
    }
    @media(max-width: 520px){
      padding: 0 15px;
    }
    @media(max-width: 479px){
      width: 50%;
    }

  }
  &--menu{

    li{
      &:first-child{
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }
    a{
      &:hover{
        text-decoration: underline;
      }
    }

  }
  &--links{
    @media(max-width: 479px){
      width: 100%;
      margin-top: 30px;
    }
    li{
      text-transform: uppercase;
      &+li{
        margin-top: 30px;
      }
    }


  }
}

#modal-group{
  .modal-dialog{
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    @media(max-width: 767px){
      top: 0;
      transform: none;
    }
  }
  .close{
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;
    span{
      display: none;
    }

  }
  .modal-content{
    border-radius: 0 0 30px 0;
    border: none;
  }
  .modal-body{
    padding: 30px 50px;
    @media(max-width: 767px){
      padding: 30px 15px;
    }
  }
  h3{
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 1px;
    color: #39cb8a;
    margin: 0;
    text-transform: uppercase;
    font-weight: 400;
  }

  //form
  form{
    @include placeholder(#000);
    .btn-green{
      margin-top: 55px;
    }
  }
  .modal-group{
    margin-left: -10px;
    margin-right: -10px;
    &__wrapper{
      overflow: hidden;
    }
    &__item{
      float: left;
      width: 33.333%;
      margin-top: 15px;
      padding: 0 10px;
      .title{
        font-size: 12px;
        font-weight: 300;
        line-height: 14px;
        color: #000000;
        margin-bottom: 30px;
      }
      &:nth-child(1){
        .title{
          margin-bottom: 40px;
        }
      }
      &:nth-child(2){
        .title{
          margin-bottom: 20px;
        }
      }
      .checkboxes{
        label{
          line-height: 20px;
          color: #000000;
          font-size: 12px;
          font-weight: 300;
          padding-left: 30px;
          position: relative;
          display: block;
          cursor: pointer;
          input{
            display: none;
            &:checked{
              &+.indicator{
                &:before{
                  opacity: 1;
                }
              }
            }
          }
          .indicator{
            display: block;
            width: 20px;
            height: 20px;
            border: solid 1px rgba(0, 0, 0, 0.2);
            position: absolute;
            background: #fff;
            left: 0;
            top: 0;
            transition-duration: 0.3s;
            &:before{
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              transition-duration: 0.3s;
              height: 12px;
              width: 12px;
              text-align: center;
              background: #39cb8a;
              content: '';
              position: absolute;
              opacity: 0;
            }
          }
          &:hover{
            color: #39cb8a;
          }


          &+label{
            margin-top: 10px;
          }
        }
      }
      input[type=text],
      input[type=email]{
        line-height: 14px;
        padding: 3.5px;
        border: none;
        border-bottom: solid 1px rgba(0, 0, 0, 0.2);
        transition-duration: 0.3s;
        font-size: 12px!important;
        font-weight: 300;
        color: #000000;
        width: 100%;
        display: block;

        &:hover,&:focus{
          border-color: #39cb8a;
        }
        &+input{
          margin-top: 20px;
        }
      }
      .comments{
        position: relative;
        //&:before{
        //  background: rgba(0, 0, 0, 0.2);
        //  height: 1px;
        //  width: 100%;
        //  top: 32px;
        //  content: '';
        //  position: absolute;
        //  display: block;
        //  left: 0;
        //
        //}
        //&:after{
        //  background: rgba(0, 0, 0, 0.2);
        //  height: 1px;
        //  width: 100%;
        //  top: 75px;
        //  content: '';
        //  position: absolute;
        //  display: block;
        //  left: 0;
        //
        //}
        textarea{
          font-family: 'Rubik', sans-serif;
          line-height: 16px;
          //border: none;
          border: solid 1px rgba(0, 0, 0, 0.2);
          transition-duration: 0.3s;
          font-size: 12px;
          font-weight: 300;
          color: #000000;
          width: 100%;
          display: block;
          resize: none;
          height: 86px;
          &:focus{
            border-color: #39cb8a;
          }
        }
      }
      .bestprice--item{
        margin-left: 0;
        float: none;
        display: block;
        width: 100%;
        max-width: 100%;
        .input-group{
          max-width: 180px;
        }
        .pickers,.input-group{
          border-color: rgba(0, 0, 0, 0.2);
          &:hover{
            border-color: #39cb8a;
          }
        }
        @extend %clearfix;
        &+.bestprice--item{
          margin-top: 32px;
        }
      }
      @media(max-width: 767px){
        width: 100%;
      }
    }

  }
  .success{
    position: absolute;
    display: none;
    background: #fff;
    top: 30px;
    bottom: 0px;
    left: 50px;
    right: 50px;
    z-index: 12345;
    border-radius: 0 0 30px 0 ;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #262626;
    padding: 50px;

    &.active{
      display: block;
    }
  }

}
.modal-adv{
  padding-right: 0!important;
  .modal-dialog{
    //max-width: 400px;
    max-width: 100%;
    top: 0;
    width: 100%;
    margin: 0 auto;
    transform: scale(0);
    //top: 50%;
    //transform: translateY(-50%);
  }
  .close{
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;
    span{
      display: none;
    }

  }
  .modal-content{
    border-radius: 0 0 50px 0 ;
    border: none;
  }
  .modal-body{
    padding: 0;
    background-position: bottom right;
    background-repeat: no-repeat;
    -webkit-background-size: 50%;
    background-size: 50%;
    min-height: 100vh;
    @extend .flex-wrapper;
    align-items: center;
    justify-content: center;
  }
  .tour-hero--form{
    float: none;
    position: relative;
    //top: 50%;
    //transform: translateY(-50%);
    //margin-left: auto;
    //margin-right: auto;
    @media(max-width: 767px){
      display: block;
      margin-top: 10px;
    }
  }
  .success{
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    left: 0;
    border-radius: 0 0 50px 0 ;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #262626;
    padding: 50px;

    &.active{
      display: block;
    }
  }
  &.in{
    .modal-dialog{
      transform: scale(1);
    }
  }

}

.form-group{
  //padding-left: 15px;
  //padding-right: 15px;
  //margin-bottom: 30px;
  margin-top: 30px;
  position: relative;
  //max-width: 430px;
  //font-family: "HelveticaNew", HelveticaNew, Arial, sans-serif;
  @media(max-width: 767px){
    max-width: 100%;
  }
  label{
    font-size: 12px;
    //color: #d3d3d3;
    font-weight: 400;
    margin: 0;
    padding: 0;
    display: block;
  }
  .form-label{
    position: absolute;
    left: 0;
    top: 0;
    line-height: 26px;
    transform: translateY(0);
    transition: transform 0.3s ease-in-out, font-size 0.3s ease-in-out;
    color: #000;
    padding: 0 3.5px;
  }
  &.standart{
    &.focused{
      .form-label{
        transform: translateY(-23px);
        transition: transform 0.3s ease-in-out, font-size 0.3s ease-in-out;
        color: #39cb8a;
      }
    }
  }
}