
//bestprice -- all pages
.bestprice{
  max-width: 100%;
  //margin-top: 60px;
  box-shadow: 0 -8px 20px 0 rgba(0, 0, 0, 0.1);
  //overflow: hidden;
  .btn-submit__mobile{
    float: right;
    max-width: 200px;
    margin-top: 20px;
    margin-right: 15px;
    //width: 100%;
    border-radius: 24px;
 //   float: right;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    padding: 11px 40px;
    text-align: center;
    cursor: pointer;
    border: none;
    background: #3acc8a;
    font-size: 12px;
   // margin: 0;
    overflow: hidden;
    display: block;
    box-shadow: 0px 3px 10px 0px rgba(58, 204, 138, 0.6);
    &:before{
      content: url("../img/btn-arrow-white.png");
      position: absolute;
      right: 15px;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition-duration: 0.3s;
    }
    &:after{
      width: 94px;
      height: 94px;
      background-color: #ffffff;
      position: absolute;
      top: -26px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      transition-duration: 0.3s;
      content: '';
      display: block;
      border-radius: 50%;
      opacity: 0;
    }
    &:hover{
      //background: darken(#3acc8a,15%);
      &:before{
        opacity: 1;
      }
    }
    &:active{
      &:after{
        opacity: 0.4;
      }
    }
    @media(min-width: 1201px){
      display: none;
    }
  }
  .container{
    @extend %clearfix;
    max-width: 1200px;
    padding: 0 15px;

  }
  &--title{
    float: left;
    //padding: 27px 50px 27px 100px;
    padding: 25px 20px 25px 0;
    background: #fcc915;
    max-width: 250px;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    &:after{
      content: '';
      border-top: 51px solid transparent;
      //border-left: 52px solid #fcc915;
      border-left: 41px solid #fcc915;
      border-bottom: 51px solid transparent;
      position: absolute;
      left: 100%;
      top: 0;
      width: 0;
      height: 0;

    }
    &:before{
      background: #fcc915;
      content: '';
      width: 999em;
      right: 100%;
      position: absolute;
      top: 0;
      height: 100%;
      display: block;

    }
    @media(max-width: 1300px){
      padding: 27px 0px 27px 0;
    }
    @media(max-width: 1200px){
      font-size: 14px;
      line-height: 17px;
      //margin-right: 70px;
    }
    //@media(max-width: 1200px){
    //  float: none;
    //  background: transparent;
    //  width: 100%;
    //  text-align: center;
    //  padding: 30px 0 15px;
    //  max-width: 100%;
    //  br{
    //    display: none;
    //  }
    //  &:after{
    //    display: none;
    //  }
    //}

  }
  &--content{
    max-width: 1000px;
    padding: 28px 0 20px;
    float: right;
    @extend %clearfix;
    @media(max-width: 1200px){
      display: none;
      width: 100%;
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
    @media(max-width: 850px){
      padding: 0;
      max-width: 400px;


    }
    @media(max-width: 767px){
      display: none;
    }


  }
  &--item{


    position: relative;
    @media(min-width:851px){
      float: left;
      margin: 0 10px;
      //margin: 0 1%;
      &.towns,
      &.dates{
        width: 28%;
      }
      &.peoples,
      &.btns{
        width: 18%;
      }
    }
    @media(max-width: 850px){
      margin: 30px 15px;
    }
    &>*{
      margin: 4px 0;
    }
    select{
      border: solid 1px rgba(0, 0, 0, 0.2);
      line-height: 38px;
      height: 40px;
      display: block;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
      z-index: 1;
      position: relative;
      background: transparent;
      padding: 0 20px;
      &::-ms-expand {
        display: none;
      }
      &~.fa{
        position: absolute;
        height: 40px;
        line-height: 40px;
        right: 20px;
        top: 0;
        z-index: 0;
      }
    }
    .pickers{
      width: 100%;
      border: solid 1px rgba(0, 0, 0, 0.2);
      transition-duration: 0.3s;
      @extend %clearfix;
      position: relative;
      .fa{
        position: absolute;
        width: 6px;
        text-align: center;
        left: 50%;
        margin-left: -3px;
        line-height: 40px;
        height: 50px;
      }
    }
    .datepicker{
      padding-left: 40px;
      line-height: 38px;
      width: 50%;
      border: none;
      float: left;
      background: transparent url("../img/datepicker.png") 20px center no-repeat;
      font-size: 14px;
      cursor: pointer;
     // color: #000000;
    }
    p{
      font-size: 12px;
      font-weight: 300;
      margin: 0;
      position: absolute;
      bottom: 100%;
      transition-duration: 0.3s;
    }
    .input-group{
      transition-duration: 0.3s;
      border: solid 1px rgba(0, 0, 0, 0.2);
      line-height: 38px;
      height: 40px;
      overflow: hidden;
      padding-left: 9px;
      padding-right: 9px;
      &>*{
        float: left;
        text-align: center;
        line-height: 38px;
        height: 38px;
        font-size: 14px;
      }
      .button-minus,.button-plus{
        background: transparent;
        border: none;

        cursor: pointer;
        transition-duration: 0.3s;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        line-height: 32px;
        margin: 3px 0 3px;
        display: block;
        &:hover{
          background: #3acc8a;
        }
      }
      .button-minus{
        float: left;
      }
      .button-plus{
        float: right;
      }

      .guests{}
      .quantity-field{
        background: transparent;
        border: none;
        float: left;
        //width: 50%;
        display: block;
        overflow: hidden;
        width: calc(100% - 64px);
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          appearance: none;
          -webkit-appearance: none;
          margin: 0;
        }
      }

    }
    .btn-submit{
      width: 100%;
      border-radius: 24px;
      text-transform: uppercase;
      color: #ffffff;
      font-weight: 700;
      padding: 11px 40px;
      text-align: center;
      cursor: pointer;
      border: none;
      background: #3acc8a;
      font-size: 12px;
      letter-spacing: 1px;
      margin: 0;
      overflow: hidden;
      box-shadow: 0px 3px 10px 0px rgba(58, 204, 138, 0.6);
      &:before{
        content: url("../img/btn-arrow-white.png");
        position: absolute;
        right: 15px;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition-duration: 0.3s;
      }
      &:after{
        width: 94px;
        height: 94px;
        background-color: #ffffff;
        position: absolute;
        top: -26px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        transition-duration: 0.1s;
        content: '';
        display: block;
        border-radius: 50%;
        opacity: 0;
      }
      &:hover{
        //background: darken(#3acc8a,15%);
        &:before{
          opacity: 1;
        }
      }
      &:active{
        &:after{
          opacity: 0.4;
          width: 100%;
        }
      }
    }

    &:hover{
      p{
        color: #39cb8a;

      }
      input,.nice-select,.pickers,.input-group{
        border-color: #39cb8a!important;
      }
    }
  }
  &.sticky{
   //@media(min-width: 1200px){
     position: fixed;
     bottom: 0;
     width: 100%;
     background: #fff;
     padding: 0;
     //box-shadow: none;
     z-index: 11;
    margin-top: 0;
   //}
  }
}

//about_hostel --all pages
.about_hostel{
  -webkit-background-size: cover;
  background-size: cover;
  .container{
    -webkit-background-size: cover;
    background-size: cover;
    min-height: 570px;
    padding: 80px 100px 0;
    @media(max-width: 767px){
      min-height: 245px;
      //min-height: 100vh;
      padding: 30px 15px 40px;
    }
  }
  .text{
    //max-width: 650px;
    color: #ffffff;
    p{
      font-weight: 700;
      font-size: 48px;
      display: block;
      line-height: 1.13;
      //&:first-child{
      //  margin-bottom: 30px;
      //}
      @media(max-width: 767px){
        //font-size: 24px;
        font-size: 18px;
      }
      //@media(max-width: 400px){
      //  font-size: 20px;
      //}
    }
    .big{
      font-size: 96px;
      line-height: 0.9;
      font-weight: 900;
      margin-bottom: 30px;
      @media(max-width: 767px){
        //font-size: 48px;
        font-size: 36px;
        line-height: 1;
        margin-bottom: 10px;
      }
      //@media(max-width: 400px){
      //  font-size: 40px;
      //}
      &+.btn{
        margin-top: 0!important;
      }
    }
    .btn-white{
      display: inline-block;
      vertical-align: top;
      margin-top: 30px;

    }
  }
  &.lowheight{
    @media(max-width: 767px){
      margin-top: 30px;
    }
    .container{
      min-height: 520px;

      padding: 80px 100px 50px;
      @media(max-width: 767px){
        min-height: 245px;
        padding: 100px 15px 50px;
      }
    }
  }
  &+.bestprice{
   @media(min-width: 768px){
     margin-top: 60px;
   }
  }
}

//map block -- contacts page
.map_towns{

  .map_select_trigger{
    display: none;
    &.active{
      display: block;
    }
  }
  &--wrapper{
    position: relative;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 0 50px;
  }
  .inner{
    padding: 0 15px;
    &>h2{
      margin: 0 0 30px;
      font-size: 48px;
      font-weight: 700;
      @media(max-width: 767px){
        font-size: 18px;
        margin: 0 0 10px;
      }
    }
  }
  &--info{
    width: 380px;
    background-color: rgb(255, 255, 255);

    border-radius: 0 0 0 50px;
    padding: 30px 50px;
    @media(max-width: 1200px){
      padding: 25px;
      width: 335px;
      border-radius: 0;
    }
    @media(max-width: 767px){
      width: 100%;
      padding: 25px 15px;
      .select-wrapper{
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &--map{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 380px;
    right: 0;
    @media(max-width: 1200px){
      left: 335px;
    }
    @media(max-width: 767px){
      position: relative;
      left: 0;
      width: 100%;
    }
    &__item{
      height: 100%;
      @media(max-width: 767px){
        height: 100vh;
      }
    }
    iframe{
      width: 100%!important;
      height: 100%!important;
    }

  }
  &--list{
    &__item{
      @media(max-width: 767px){
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }
      h2,h3{
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 400;
        margin: 60px 0 0;
        color: #39cb8a;
        letter-spacing: 1px;
        &.mt_fix{
          margin-top: 30px;
        }
      }
      p{
        font-size: 12px;
        strong{
          font-weight: 400;
          font-size: 14px;
          display: block;
        }
      }
      .address,.phones,.mail{
        margin-top: 30px;
        @media(max-width: 767px){
          margin-top: 10px;
        }
      }

    }
  }
  .btn{
    display: inline-block;
    padding: 0 60px;
    margin-top: 40px;
    &.mt_fix{
      margin-top: 56px;
    }
  }
  &.single{
    .map_towns--wrapper{

    }

    h3{
      margin-top: 0;
    }
    .btn{
      margin-top: 126px;
    }
    @media(max-width: 767px){
      &+.about_hostel{
        margin-top: 0!important;
      }
    }
  }

  &.map_contacts{
    padding-top: 30px;
    @media(max-width: 767px){
      padding-top: 8px;
    }

  }

  @media (max-width: 767px){

    .map_towns--wrapper{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
      margin-left: -15px;
      margin-right: -15px;
    }
    .map_towns--info{
      padding-bottom: 60px;
      order: 2;
    }
    .map_towns--map{
      order: 1;
    }
    .btn-green{
      display: none;
    }
  }
}

//group --group page
.group{
  .inner{
    padding: 0 15px;

  }
  h2,h1{
    margin: 0 0 20px;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.13;
    @media(max-width: 767px){
      font-size: 18px;
      margin: 0 0 10px;
    }
  }
  h1{
    @media(min-width: 768px){
      display: none;
    }
  }
  &--wrapper{
    position: relative;
    @media(min-width: 768px){
      padding-right: 400px;
    }
    @media(max-width: 767px){
      padding-bottom: 95px;
      position: relative;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    }
  }
  &--white{
    padding: 30px 15px 30px;
    @media(min-width: 768px){
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 400px;
      border-radius: 0 0 50px 0;
      padding: 40px 50px 30px;
    }
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    img{
      @media(min-width: 768px){
        display: none;
      }
      margin-left: -15px;
      margin-right: -15px;
      display: block;
      max-width: calc(100% + 30px);
    }
    h2,h3{
      margin: 0 0 15px;
      font-weight: 700;
      font-size: 48px;
      line-height: 1.13;
      @media(max-width: 767px){
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 5px;
        margin-top: 20px;
      }
    }
    p{
      margin: 10px 0 0;
      font-size: 24px;
      line-height: 1.42;
      @media(max-width: 767px){
        font-size: 14px;
        line-height: 16px;
      }
    }
    @media(max-width: 767px){
      padding-top: 0;
      box-shadow: none;
      padding-bottom: 0;
    }
    &__footer{
      position: absolute;
      bottom: 30px;
      left: 50px;
      right: 50px;
      //overflow: hidden;
      @extend %clearfix;
      a{
        line-height: 48px;
        text-decoration: underline;
        position: relative;
        font-size: 12px;
        letter-spacing: 1px;
        &:before{
          transition-duration: 0.3s;
          left: -20px;
          right: -20px;
          border-radius: 24px;
          background: #3acc8a;
          opacity: 0;
          position: absolute;
          top: 0;
          content: '';
          height: 100%;
          display: block;
        }
        &:hover{
          &:before{
            opacity: 0.4;
          }
        }
        &:focus{
          &:before{
            opacity: 0.4;
            border-radius: 13px;
          }
        }
      }
      a,button{
        float: left;
        text-transform: uppercase;
        &+a,&+button{
          float: right;
        }
        &.btn-green{

          height: 48px;
          font-weight: 700;
          line-height: 14px;
          max-width: 180px;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
          span {
            position: relative;
            line-height: 14px;
            letter-spacing: 1px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      @media(max-width: 767px){
        position: absolute;
        bottom: 20px;
        width: 100%;
        left: 0;
        padding: 0 15px;
      }
    }
  }
  &--info{
    -webkit-background-size: cover;
    background-size: cover;
    height: 500px;
    display: block;
    overflow: hidden;
    position: relative;

    &:after{
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 70%);
      height: 70%;
      bottom: 0;
      left: 0;
      width: 100%;
      content: '';
      position: absolute;
      display: block;
      z-index: 0;
    }
    &__list{
      overflow: hidden;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 30px;
      z-index: 1;
      @extend .flex-wrapper;
      @media(max-width: 767px){
        position: relative;
        bottom: initial;
      }
    }
    &__item{
      width: 25%;
      padding-right: 20px;
      padding-left: 10px;
      @extend %clearfix;
      @media(max-width: 1024px){
        width: 50%;
        margin-top: 20px;
        padding-left: 15px;
      }
      @media(max-width: 767px){
        width: 100%;
      }

      .icon{
        width: 38px;
        height: 38px;
        float: left;
        border-radius: 50%;
        background: #3acc8a;
        position: relative;
        margin-right: 10px;
        img{
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
      p{
        display: block;
        overflow: hidden;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #ffffff;
        position: relative;
        @media(max-width: 1024px){
          top: 50%;
          transform: translateY(-50%);
        }
        @media(max-width: 767px){
          color: #000000;
        }
      }
    }
    @media(max-width: 767px){
      background: white;
      background-image: none!important;
      height: auto;
      &:after{
        display: none;
      }
    }
  }
  &-only{
    padding-top: 30px;
    @media(max-width: 767px){
      margin-bottom: 40px;
      .group--wrapper{
        box-shadow: none;
      }
      .group--white{
        padding-left: 0;
        padding-right: 0;
        img{
          margin: 0 auto;
          width: calc(100%);
        }
      }
      .group--info__item{
        padding-left: 0;
        padding-right: 0;
      }

    }
  }
}

//promo
.promo{
  //@media(min-width: 768px){
  //  padding-top: 110px!important;
  //  padding-bottom: 0!important;
  //}
  .inner{
    padding: 0 15px;

  }
  //padding-bottom: 80px;

  h1,h2{
    margin: 0 0 30px;
    font-size: 48px;
    line-height: 1.13;
    font-weight: 700;
    @media(max-width: 767px){
      font-size: 40px;
    }
  }
  &--select{
    max-width: 290px;
    p{
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 10px;
      line-height: 14px;
    }
  }
  &--wrapper{
    clear: both;
    @extend .flex-wrapper;
    width: 100%;
    //max-width: 840px;
    margin: -20px auto 0;
    justify-content: space-between;
    //&.fix{
    //
    //  width: auto;
    //  .promo--item{
    //    max-width: 31%;
    //    @media(max-width: 767px){
    //      max-width:100%;
    //    }
    //  }
    //}
    &.owl-carousel{
      width: auto;
      margin-left: -15px;
      margin-right: -15px;
      //@media(min-width: 768px){
      //  margin-left: -30px;
      //  margin-right: -30px;
      //}
      .owl-item{
       padding: 30px 15px;
        .promo--item{
          border-radius: 0 0 30px 0;
          overflow: hidden;
          max-width: 100%;
          margin: 0;
          &__img{
            img{
              @media(max-width: 767px){
                height: 186px;
              }
            }
          }
          &__content{
            padding: 20px;
            border-radius: 0 0 30px 0;
            .controls{
              margin-top: 0;
            }
            .title{
              font-size: 14px;
              line-height: 1.29;
              letter-spacing: 0.78;
            }
          }
        }

      }
      .owl-next,.owl-prev{
        position: absolute;
        width: 24px;
        font-size: 16px;
        line-height: 24px;
        height: 24px;
        border-radius: 50%;
        background: #fff;
        top: 65px;
        @media(min-width: 768px){
          top: 50%;
          transform: translateY(-50%);


        }
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);

        color: #000000;
        border: none;

        .fa{
          text-shadow: none;
        }
      }
      .owl-next{
        right: 5px;
        @media(min-width: 768px){
          right: 7.5px;
        }
      }
      .owl-prev{
        left: 5px;
        @media(min-width: 768px){
          left: 7.5px;
        }
      }
    }
  }
  &--item{
    width: 100%;
    max-width: 32%;
    margin: 20px 0 0;
    @media(max-width: 840px){
      margin: 20px 0;
    }
    @media(max-width: 767px){
      max-width:100%;
    }

    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);

    &__img{
      width: 100%;
      img{
        //height: 440px;
        height: 392px;
        width: 100%;
        object-fit: cover;
        @media(max-width: 399px){
          height: 340px;
        }
      }
    }
    &__content{
      position: relative;
      z-index: 1;
      margin-top: -50px;
      background: #fff;
      width: 100%;
      border-radius: 50px 0 0 0;
      padding: 30px 40px;
      @media(max-width: 399px){
        padding: 30px 15px;
      }
      .title{
        text-transform: uppercase;
        font-size: 18px;
        color: #39cb8a;
        line-height: 26px;
        letter-spacing: 1px;
      }
      .town{
        font-size: 14px;
        line-height: 1.43;
      }
      .controls{
        margin-top: 16px;
        //overflow: hidden;
        @extend %clearfix;
        a,button{
          font-size: 12px;
          text-transform: uppercase;
          height: 48px;
          float: left;
          max-width: 180px;
          line-height: 14px;
          span{
            position: relative;
            line-height: 14px;
            letter-spacing: 1px;
            top: 50%;
            transform: translateY(-50%);
          }
          &.btn-green{
            float: right;
            font-weight: 700;
            height: 46px;
            line-height: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
              top: 0;
              transform: none;
            }
            @media(max-width: 399px){
              padding: 0 20px;
            }
          }
        }
        a:not(.btn-green){
          line-height: 48px;
          text-decoration: underline;
          position: relative;
          &:before{
            transition-duration: 0.3s;
            left: -20px;
            right: -20px;
            border-radius: 24px;
            background: #3acc8a;
            opacity: 0;
            position: absolute;
            top: 0;
            content: '';
            height: 100%;
            display: block;
          }
          &:hover{
           &:before{
             opacity: 0.4;
           }
          }
          &:focus{
            &:before{
              opacity: 0.4;
              border-radius: 13px;
            }
          }
        }
      }
    }
  }
  &--terms{
    @media(min-width: 768px){
      padding-top: 110px;
      padding-bottom: 0;
    }
    @media(max-width: 767px){
      padding-top: 30px;
      padding-bottom: 0!important;
    }
    &__details{
      max-width: 600px;
      font-size: 14px;
      p{
        line-height: 1.4;
        margin: 0;
      }
    }
  }
  &--town{
    .promo--item{
      max-width: 32%;
      margin-left: 0;
      margin-right: 0;
      @media(max-width: 1023px){
        max-width: 400px;
      }
    }
    .promo--wrapper{
      max-width: initial;
      justify-content: space-between;
      //margin-top: -20px;
      @media(max-width: 1023px){
        max-width: 840px;
        justify-content: space-around;
      }
    }
  }
  &-only{
    padding-top: 30px;
    @media(max-width: 767px){
      padding-bottom: 0!important;
    }
  }
  &.owl-inside{
    &+.about_hostel{
      margin-top: 30px;
      @media (min-width: 768px){
        margin-top: 90px;
      }
    }
  }

  @media(max-width: 767px){
    padding-bottom: 30px;
    h1,h2{
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    &+.about_hostel{
      margin-top: 40px;
    }
  }

}

//promo hero (may be another promo too
.hero{
  -webkit-background-size: cover;
  background-size: cover;
  background-position: top center;
  .item{
    -webkit-background-size: cover;
    background-size: cover;
    background-position: top center;
  }
  .owl-nav{
    @extend .container;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 35px;
    margin: auto;
    @media(max-width: 767px){
      display: none;
    }
  }
  .owl-next,.owl-prev{
    position: absolute;
    font-size: 40px;
    line-height: 35px;
    height: 35px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    color: #ffffff;
    background: transparent;
    border: none;
  }
  .owl-next{
    right: 20px;
  }
  .owl-prev{
    left: 20px;
  }
  .db{
    margin-top: 30px;
    @media(max-width: 767px){
      margin-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .container{
    padding: 0;
    @media(max-width: 767px){
      padding: 30px 0;
    }
  }
  .hero--home{
    @media(min-width: 768px){
      padding: 80px 0;
    }
  }
  .inner{
    padding: 0 15px;
    @extend %clearfix;
  }
  &--form{
    max-width: 400px;
    float: right;

    padding: 30px 50px ;
    background: #fff;
    border-radius: 0 0 50px 0;
    .title{
      text-transform: uppercase;
      font-size: 18px;
      color: #39cb8a;
    }
    .town{
      font-size: 14px;
    }
    @media(max-width: 767px){
      padding: 30px 15px;
    }
    form{
      margin-top: 15px;
      .bestprice--item{
        width: 100%;
        float: none;
        margin: 50px 0 0;
      }

      .btn-green{
        margin-top: 80px;
        height: 48px;
        line-height: inherit;
        span{
          position: relative;
          line-height: 14px;
          letter-spacing: 1px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  &--home{
    position: relative;
    padding: 115px 0;

    @media(max-width: 767px){
      //height: calc(100vh - 104px);
      padding: 0;
    }
    .text{
      position: relative;
      @media(max-width: 767px){
        //top: 50%;
        //transform: translateY(-50%);
      }

      max-width: 500px;
      h1{
        font-size: 96px;
        color: #ffffff;
        font-weight: 900;
        margin: 0;
        line-height: 86px;
        @media(max-width: 1280px){
          font-size: 75px;
          margin-bottom: 10px;
        }
        @media(max-width: 767px){
          font-size: 36px;
          line-height: 1;
        }
      }
      p{
        font-size: 48px;
        color: #262626;
        margin: 30px 0 0 0;
        font-weight: 700;
        @media(max-width: 1280px){
          font-size: 36px;
        }
        @media(max-width: 767px){
          font-size: 18px;
          margin-top: 10px;
        }
      }
    }
  }
  @media(min-width: 768px){
    min-height: 550px;
    .mob-br{
      display: none;
    }
  }
  @media(max-width: 767px){
    min-height: 240px;
  }
  &--main{
    @media(min-width: 768px){
      min-height: 570px;
    }
    @media(max-width: 767px){
      height: 240px;
    }
  }

  &-owl{

    .owl-stage {
      display: flex
    }

   .owl-item {
      display: flex;
      flex: 1 0 auto;
     .item{
        width: 100%;
      }
    }
    .owl-dots{
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      text-align: center;
      .owl-dot{
        border: none;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #e5e5e5;
        display: inline-block;
        vertical-align: top;
        margin: 10px 5px;
        transition-duration: 0.3s;
        &.active{
          transform: scale(1.6);
        }
      }
    }
  }
  &.promohero{
    padding-top: 60px;
    @media(max-width: 767px){
      padding-top: 30px;
      position: relative;
      .container{
        padding-top: 0;
      }
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
        top: 0;
        left: 0;
      }
      .hero--form{
        display: none;

      }
    }
    .promohero--mobile{
      position: relative;
      z-index: 1;
      h1{
        color: #ffffff;
        font-size: 36px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
      }
      button{
        max-width: 180px;
        font-size: 12px;
        text-transform: uppercase;
        height: 48px;
        line-height: 14px;
        span{
          position: relative;
          line-height: 14px;
          letter-spacing: 1px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      @media(min-width: 768px){
        display: none;
      }
    }
  }
}

//slow travel hero
.static{
  color: #ffffff;
  background-repeat: no-repeat;
  background-position: top center;
  .container{
    -webkit-background-size: cover;
    background-size: cover;
    background-position: top center;
    .inner{
      height: 550px;
      padding: 0 15px;
      overflow: hidden;
      @media(max-width: 767px){
        padding: 0 15px;
        height: 100vh;
      }
    }

  }
  h1{
    font-size: 96px;
    line-height: 86px;
    font-weight: 900;
    max-width: 320px;
    position: relative;
    //top: 50%;
    margin: 80px 0 0;
    //transform: translateY(-50%);
    @media(max-width: 767px){
      font-size: 75px;
    }

  }

}

//places
.places{
  .inner{
    padding: 0 15px;
  }
  h2{
    margin: 0 0 20px;
    font-size: 48px;
    font-weight: 700;
    @media(max-width: 767px){
      font-size: 40px;
    }
  }
  &--wrapper{
    @extend .flex-wrapper;
    &.owl-carousel{
      margin-left: -15px;
      margin-right: -15px;
      width: auto;
    }
    .owl-item{
      padding: 15px 15px 20px;
    }
    .owl-prev,.owl-next{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      border: none;
      line-height: 30px;
      text-align: center;
      transition-duration: 0.3s;
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
      &:hover{
        background: #fcc915;
      }
    }
    .owl-prev{
      left: 10px;
    }
    .owl-next{
      right: 10px;

    }
    &__item{
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
      width: 49%;
      margin-bottom: 20px;
      position: relative;
      @media(max-width: 767px){
        width: 100%;
        margin-bottom: 0;
      }
      .icon{
        position: absolute;
        top: 20px;
        left: 20px;
        border-radius: 50%;
        display: block;
        width: 60px;
        height: 60px;
        z-index: 1;
        background: #3acc8a;
        @media(max-width: 767px){
          display: none;
        }
        img{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          transition-duration: 0.3s;

        }
        &:hover{
          img{
            transform: scale(1.3);
          }
        }

      }
      .inner__wrapper{
        position: relative;
        overflow: hidden;
        //height: 100%;
        height: 550px;
        max-height: 100vh;
        @media(max-width: 767px){
          height: 436px;
        }
      }
      .img{
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        @media(max-width: 767px){
          //height: 360px;
          height: 100vh;
        }
        img{
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }
      &.active{
      //  @media(min-width: 768px){
         .places--wrapper__content{
           transform: translateY(0)!important;
           //top: 200px;
           //transition: top 0.3s ease;
         }
   //    }
        @media(max-width: 767px){
          .places--wrapper__description{
     //       display: block;
          }
        }
      }
    }
    &__content{
      cursor: pointer;
      padding: 35px 50px 0;
      background: #fff;
      border-radius: 50px 0 0 0;
      width: 100%;
      //position: relative;
      //@media(min-width: 768px){
        position: absolute;
        //top: 100%;
        bottom: 0;
       transition-duration: 0.3s;
        left: 0;
      //}
      overflow: hidden;
      //margin-top: -110px;
      @media(max-width: 767px){
        padding: 35px 15px;
      }
    }
    &__caption{
      overflow: hidden;
      padding-bottom: 30px;
      .left{
        float: left;
        max-width: 50%;
        .title{
          font-size: 18px;
          text-transform: uppercase;
          font-weight: 400;
          color: #39cb8a;
          line-height: 23px;
          letter-spacing: 1px;
        }
        .address{
          font-size: 14px;
          line-height: 1.43;
        }
      }
      .right{
        float: right;
        max-width: 50%;
        .btn{
          display: inline-block;
          vertical-align: top;
        }
      }
    }
    &__description{
      @media(max-width: 767px){
    //    display: none;
      }
      overflow: hidden;
      font-size: 14px;
      line-height: 1.43;
      padding-bottom: 20px;
      h4{
        margin-top: 30px;
        margin-bottom: 10px;
        line-height: 14px;
        font-size: 12px;
        font-weight: 300;
        color: #000000;
        &:first-child{
          margin-top: 0;
        }
      }
      p{
        margin: 0;
        font-weight: 400;
      }
    }

  }
  &--control{
    text-align: center;
    padding: 60px 0 0;
    @media(max-width: 1024px){
      padding-top: 30px;
    }
    a,button{
      line-height: 48px;
      display: inline-block;
      vertical-align: top;
      text-transform: uppercase;
      margin: 0 15px;
      font-size: 12px;
      &.btn-green{
        line-height: 46px;
        padding: 0 50px;
      }
    }
    @media(max-width: 767px){
      display: none;
    }
  }
  &+.promo{
    @media(max-width: 767px){
      margin-top: -20px;
    }
  }

}



/*home*/
.like{
  padding-top: 100px;
  h2{
    margin: 0;
    font-size: 48px;
    font-weight: 700;

  }
  @media(max-width: 767px){
    //padding-top: 30px;
   h2{
     font-size: 18px;
     line-height: 22px;
   }
  }
  .inner{
    padding: 0 15px;
  }
  &--wrapper{
    margin-top: 10px;
    @extend .flex-wrapper;
    @media(max-width: 767px){
      width: 100%;
    }
    &.owl-carousel{
      margin-left: -15px;
      margin-right: -15px;
      width: auto;
    }
    .owl-item{
      padding: 15px 15px 20px;
      @media(max-width: 767px){
        padding-top: 0;
      }
    }
    .owl-prev,.owl-next{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      border: none;
      line-height: 30px;
      text-align: center;
      transition-duration: 0.3s;
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
      &:hover{
        background: #fcc915;
      }
    }
    .owl-prev{
      left: 10px;
    }
    .owl-next{
      right: 10px;
    }
  }
  &--item{
    position: relative;
    height: 520px;
    margin-top: 20px;
    overflow: hidden;
    box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &.short{
      height: 330px;
    }
    &.w_33{
      width: 32%;
      @media(max-width: 767px){
        width: 100%;
      }
    }
    &.w_66{
      width: 66%;
      @media(max-width: 767px){
        width: 100%;
      }
    }
    @media(min-width: 768px){

      &:nth-child(4),&:nth-child(5){
        transform: translateY(-190px);
      }
    }
    @media(max-width: 767px){
      width: 100%;
      height: 436px;
      padding-top: 0;
      margin-top: 0;
      &.short{
        height: 436px;
      }
    }
    .text{
      //display: none;//temp
      padding: 30px 50px;
      background: #fff;
      border-radius: 50px 0 0 0;
      width: 100%;
      position: absolute;
      bottom: 0;
      //bottom: 0;
      //top: 100%;
      //transform: translateY(-90px);
      @media(max-width: 767px){
        transform: translateY(-60px);
        padding: 20px;
        border-radius: 30px 0 0 0;
      }
      overflow: hidden;
      transition-duration: 0.3s;
      //height: 100%;
      .description{
        font-size: 14px;
        line-height: 1.43;
        color: #000000;
        padding-top: 30px;
        padding-bottom: 30px;
      }
      .title{
        font-size: 18px;
        line-height: 1;
        letter-spacing: 1px;

        text-transform: uppercase;
        color: #000000;
       // height: 90px;
        display: block;
        @media(max-width: 767px){
        //  height: 60px;
          font-size: 14px;
        }
        span{
          position: relative;
          display: block;
          //top: 50%;
          //transform: translateY(-50%);

        }

        &+*{
          //margin-top: 10px;
        }
      }

    }
    &.open{
      .text{
        transform: translateY(0)!important;
        background: #fcc915;
        @media(max-width: 767px){
          top: calc(50% + 15px);
        }
        .description{
          padding-top: 10px;
        }
      }
      .title{
        //color: #39cb8a;
      }
    }
  }
  &+.selecttown{
    @media(max-width: 767px){
      margin-top: -37px!important;
    }
  }
  &+.about_hostel{
    margin-top: 110px!important;
    @media(max-width: 767px){
      margin-top: 60px!important;
    }
  }
}

.selecttown{
  //margin-bottom: 40px;

  h2{
    font-size: 48px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
    img{
      height: 48px;
      margin-left: 20px;

    }
    &+p{
      font-size: 24px;
      @media(max-width: 767px){
        display: none;
      }
    }
    @media(max-width: 767px){
      font-size: 18px;
      line-height: 28px;
      img{
        height: 28px;
      }
    }
  }


  .inner{
    padding: 0 15px;
  }
  @media(min-width: 768px){
    &.left{
      .selecttown--wrapper{
        padding-left: 380px;
      }
      .selecttown--white{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 380px;
        border-radius: 0 0 0 50px;
        padding: 40px 50px 30px;
      }
    }
    &.right{
      .selecttown--wrapper{
        padding-right: 380px;
      }

      .selecttown--white{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 380px;
        border-radius: 0 0 50px 0;
        padding: 40px 50px 30px;
      }
    }
  }

  &--wrapper{
    position: relative;

    border-radius: 0 0 50px 0;
    margin-top: 30px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    @media(max-width: 767px){
      margin-top: 10px;
      border-radius: 0 0 30px 0;
    }

  }
  &--white{
    padding: 30px 15px 30px;
    background-color: rgb(255, 255, 255);
    //box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    .title{
      text-transform: uppercase;
      margin-top: 30px;
      font-size: 18px;
      color: #39cb8a;
      margin-bottom: 10px;
      line-height: 22px;
      letter-spacing: 1px;
      @media(max-width: 767px){
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.78px;
      }
    }
    .descr{
      font-size: 14px;
      line-height: 1.43;
    }
    p{
      margin: 0;
      font-size: 24px;
    }
    &__footer{
      position: absolute;
      bottom: 30px;
      left: 50px;
      right: 50px;
      overflow: hidden;
      @media(max-width: 767px){
        text-align: center;
      }
      a,button{
        line-height: 48px;
        float: left;
        &.btn-green{

          @media(min-width: 768px){
            float: right;
          }
          @media(max-width: 767px){
            display: inline-block;
            float: none;
            margin-left: auto;
            margin-right: auto;
          }
          line-height: 46px;
        }
      }

      &.left{
        .btn-green{
          @media(min-width: 768px){
            float: left;
          }
        }
      }
      @media(max-width: 767px){
        position: relative;
        left: 0;
        right: 0;
        bottom: inherit;
        margin-top: 25px;
      }
    }
  }
  &--info{
    -webkit-background-size: cover;
    background-size: cover;
    height: 393px;
    display: block;
    overflow: hidden;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 767px) {
      min-height: 160px;
      height: auto;

    }
  }
  &.left{
    .selecttown--wrapper{
      border-radius: 0 0 0 50px;
      @media(max-width: 767px){
        border-radius: 0 0 30px 0;

      }
    }
  }
  &+.bestprice{
    margin-top: 60px;
    @media(max-width: 767px){
      margin-top: 30px;
    }
  }
}
/*home*/


//to app
.select-wrapper{
  position: relative;
  @extend %clearfix;
  select{
    border: solid 1px rgba(0, 0, 0, 0.2);
    line-height: 38px;
    height: 40px;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    z-index: 1;
    position: relative;
    background: transparent;
    padding: 0 20px;
    &::-ms-expand {
      display: none;
    }
    &~.fa{
      position: absolute;
      height: 40px;
      line-height: 40px;
      right: 20px;
      top: 0;
      z-index: 0;
    }
  }
}

.social{
  margin-top: 30px;
  overflow: hidden;
  a{
    float: left;
    margin: 0 5px;
  }
  img{
    width: 31px;
  }
}

//chernobyl
.about-tour-after{
  padding-top: 20px;
  @media(max-width: 767px){
    padding-bottom: 20px;
  }
  .inner{
    padding: 0 15px;
  }
  .item{
    width: 15%;
    font-size: 14px;
    line-height: 20px;
    img{
      float: left;
      margin-right: 10px;
      @media(max-width: 767px){
        margin-right: 40px;
      }
    }
    span{
      overflow: hidden;
      display: block;

    }
    &.w25{
     @media(min-width: 768px){
       width: 25%;
     }
    }
    @media(max-width: 767px){
    //  width: 30%;
    //  margin-bottom: 15px;
      margin-bottom: 20px;
    //}
    //@media(max-width: 480px){
    //  width: 48%;
    //}
    //@media(max-width: 376px){
      width: 100%;
      br{
        display: none;
      }
    }

  }
  &.atour{}
}
.about_tour{
  h2{
    line-height: 1.13;
    margin: 0 0 30px;
    font-size: 48px;
    font-weight: 700;
    @media(max-width: 767px){
      font-size: 18px;
      display: none;
    }
  }
  .inner{
    padding: 0 15px;
  }
  &--inner{
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    @media(min-width: 768px){
      border-radius: 0 0 0 50px;
    }
  }
  &--program{

    padding-left: 50px;
    padding-right: 25px;
    @media(min-width: 768px){
      height: 520px;
      width: 33%;
      .mobile-btn{
        display: none;
      }
    }
    overflow: hidden;
    padding-top: 25px;
    @media(max-width: 1024px){
      padding-left: 10px;
      padding-right: 10px;
    }
    @media(max-width: 767px){

      width: 100%;
      padding-bottom: 20px;
    }
    h3{
      text-transform: uppercase;
      font-weight: 400;
      color: #39cb8a;
      font-size: 18px;
      line-height: 22px;
      //margin-bottom: 5px;
      letter-spacing: 1px;
      &+p{
        font-size: 12px;
        font-weight: 300;
        margin: 0;
        line-height: 14px;
      }
    }
    .mobile-btn{
      text-align: center;
      margin-top: 20px;
      //position: absolute;
      //top: 100%;
      //left: 0;
      //width: 100%;
      div{
        font-size: 12px;
        text-transform: uppercase;
        text-decoration: underline;
        line-height: 14px;
        display: inline-block;
        cursor: pointer;
        position: relative;
        &:before{
          transition-duration: 0.3s;
          left: -20px;
          right: -20px;
          border-radius: 24px;
          background: #3acc8a;
          opacity: 0;
          position: absolute;
          top: -10px;
          content: '';
          //height: 100%;
          display: block;
          bottom: -10px;
        }
        &:hover{
          &:before{
            opacity: 0.4;
          }
        }
        &:focus{
          &:before{
            opacity: 0.4;
            border-radius: 13px;
          }
        }
        //padding: 10px 20px;
      }
    }

  }
  &--slider{
    width: 67%;
    @media(max-width: 767px){
      width: 100%;

   //
    }
    img{
      @media(min-width: 768px){
        height: 520px;
      }
      height: 160px;
      width: 100%;
      object-fit: cover;
    }
    .owl-next,.owl-prev{
      position: absolute;
      font-size: 40px;
      line-height: 35px;
      height: 35px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      color: #ffffff;
      background: transparent;
      border: none;
      .fa{
        text-shadow: 3px 3px 10px #000;
      }
    }
    .owl-next{
      right: 20px;
    }
    .owl-prev{
      left: 20px;
    }
  }
  &--timeline{
    margin-top: 10px;
    max-height: 410px;
    @media(max-width: 767px){
      max-height: 250px;
    }
    ul{
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 14px;
      line-height: 1.43;
      @media(max-width: 1024px){
        font-size: 13px;
      }
      li{

        position: relative;
        padding-left: 60px;
        margin-bottom: 30px;
        //@media(min-width: 768px){
        //
        //}
        &:last-child{
          @media(max-width: 767px){
            margin-bottom: 0;
          }
        }
        @media(max-width: 1024px){
          padding-left: 50px;
        }
        //@media(max-width: 767px){
        //  margin-bottom: 10px;
        //  padding-left: 10px;
        //}
        .time{
          font-weight: 700;
          width: 45px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    p{
      font-size: 14px;
      line-height: 1.43;
    }
  }
  .mCSB_scrollTools .mCSB_draggerRail{
    width: 4px;
    background: #e3e3e3;
  }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    background: #39cb89;
  }

  &.stour{
    position: relative;
    @media(min-width: 768px){
      .about-tour-after{
        display: none;
      }
    }

    @media(max-width: 767px){
      margin-top: -30px;
      &+.about-tour-after{
        display: none;
      }
      .about-tour-after{
        padding-bottom: 0;
        padding-top: 40px;
        .item{
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .about_tour--inner{
        box-shadow: none;
      }
      .about_tour--program{
        order: 2;
        padding-bottom: 0;
      }
      .about_tour--title{
        //display: none;
        margin-bottom: 10px;
        h3{
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626;
          text-transform: none;
        }
        p{
          display: none;
        }
      }
      .about_tour--timeline{
        margin-top: 0;
        overflow: hidden;
      }
    }
  }
  &.atour{

    @media(max-width: 767px){
      margin-top: -30px;
      .about_tour--inner{
        box-shadow: none;
      }
      .about_tour--slider{
        order: 1;
      }
      .about_tour--program{
        padding: 0 0 60px;
      }
      .about_tour--title{
        display: none;
      }
      .about_tour--timeline{
        margin-top: 0;
      }
    }
  }
}
.tour-hero{
  padding: 60px 0 65px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  @media(min-width: 768px){
    height: 550px;
    .mobile-btn{
      display: none;
    }
  }
  @media(max-width: 767px){
    padding-top: 30px;
    padding-bottom: 70px;
    .mobile-btn{
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .inner{
    padding: 0 15px;
    @extend %clearfix;
  }
  h1{
    color: #ffffff;
    float: left;
    font-size: 96px;
    font-weight: 900;
    line-height: 0.9;
    margin: 20px 0 0;
    width: calc(100% - 450px);
    @media(max-width: 1024px){
      font-size: 60px;
      width: 100%;
      max-width: 400px;
    }
    @media(max-width: 900px){
      font-size: 50px;
      width: 100%;
      max-width: 350px;
    }
    @media(max-width: 767px){
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
      float: none;
      max-width: 100%;
      font-size: 36px;
      margin-top: 0;
      line-height: 1;

    }
  }
  &--form{
    float: right;
    border-radius: 0 0 50px 0;
    background: #fff;
    width: 400px;
    padding: 30px 50px 30px;
    @media(max-width: 1024px){
      padding: 25px;
      width: 350px;
    }
    @media(max-width: 767px){
      float: none;
      padding: 35px 50px 30px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 400px;
      display: none;
    }
    @media(max-width: 400px){
      padding: 25px;
    }
    &__title{
      text-transform: uppercase;
      font-size: 18px;
      color: #39cb8a;
      letter-spacing: 1px;
      line-height: 27px;
    }
    &__price{
      font-size: 14px;
      line-height: 1.43;
    }
    form{
      margin-top: 16px;
      @media(max-width: 767px){
        margin-top: 20px;
      }
    }
    input[type=text],
    input[type=email]{
      display: block;
      font-size: 12px!important;
      font-weight: 300;
      width: 100%;
      border: none;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      height: 30px;
      line-height: 29px;
      margin-bottom: 20px;
      &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #000000;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #000000;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #000000;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #000000;
      }
      &:hover{
        border-color: #39cb8a!important;
      }
    }
    input[type=email]{
      margin-bottom: 47px;
    }
    .oh{
      @extend %clearfix;
    }
    .pickers{
      float: left;
      position: relative;
      width: 45%;
      p{
        font-size: 12px;
        font-weight: 300;
        margin: 0;
        position: absolute;
        bottom: 100%;
      }
      input{
        border: solid 1px rgba(0, 0, 0, 0.2);
        margin: 4px 0;
        width: 100%;
        line-height: 38px;
        text-align: center;
        transition-duration: 0.3s;
        background: transparent url(../img/datepicker.png) 10px center no-repeat;
        &:hover{
          border-color: #39cb8a!important;
        }
        //&:focus{}

      }
    }
    .peoples{
      float: right;
      width: 45%;
    }
    .radio{
      @extend %clearfix;
      margin-top: 25px;

      label{
        float: left;
        &+label{
          margin-left: 30px;
          @media(max-width: 400px){
            margin-left: 20px;
          }
        }
      }
    }
    .btn{
      margin-top: 34px;
    }
    .bestprice--item{
      @media(max-width: 850px){
        margin: 0;
      }
    }
  }

}

.radio{
  label{
    padding-left: 30px;
    position: relative;
    line-height: 20px;
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
    .indicator{
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      transition-duration: 0.3s;
      border: solid 1px rgba(0, 0, 0, 0.2);
      display: block;
      &:before{
        width: 14px;
        height: 14px;
        position: absolute;
        content: '';
        display: block;
        background: #3acc8a;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transition-duration: 0.3s;
        opacity: 0;
        border-radius: 50%;
      }
    }
    &:hover{
      .indicator{
        border-color: #3acc8a;
      }
    }

    input{
      display: none;
      &:checked{
        &+.indicator{
          border-color: #3acc8a;
          &:before{
            opacity: 1;
          }
        }
      }
    }
  }
}

//towns
.buddy{
  .inner{
    padding: 0 15px;
  }
  h2{
    margin: 0 0 20px;
    font-size: 48px;
    line-height: 1.13;
    font-weight: 700;
    @media(max-width: 767px){
      font-size: 18px;
      margin: 0 0 10px;
    }
  }
  @media(min-width: 1280px){
    .w33{
      .text{
        width: auto;
        left: 0;
        right: 10px;
        display: none;
      }
    }
    .w33, .w66 .h50{
      position: relative;
      &:first-child{
        .rel{
          box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
    .h50{
      img{
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 10px);
        object-fit: cover;
      }
      &+.desc{
        .w50{
          position: relative;
          img{
            top: 0;
            bottom: 0;
            height: 100%;
            width: calc(100% - 10px);
          }
          &:first-child{
            img{
              left: 0;
            }
          }
          &:last-child{
            img{
              left: inherit;
              right: 0;
            }
          }
          &:hover{
            .text{
              opacity: 1;
              box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
            }
          }
        }

      }
    }
  }

  .w33{
    width: 33.333%;
    padding-right: 10px;
    @media(max-width:1279px){
      padding: 0;
      width: 100%;
      img{
        height: 300px;
        width: 50%;
        float: left;
        object-fit: cover;
      }
    }
    //@media(max-width: 767px){
    //  width: 50%;
    //  img{
    //    max-height: 350px;
    //    object-fit: cover;
    //  }
    //}
    //@media(max-width: 600px){
    //  width: 100%;
    //  padding: 0;
    //}
  }
  .w50{
    width: 50%;
    &:first-child{
      padding-right: 10px;
    }
    &+.w50{
      padding-left: 10px;
    }
  }
  .owl-carousel{

    @media(min-width: 1200px){
      margin-left: -10px;
      margin-right: -10px;
      width: auto;
    }
    .item{
      padding: 10px 10px 0;
    }
    .owl-nav{
      text-align: center;
    }
    .owl-next,.owl-prev{

      font-size: 40px;
      line-height: 35px;
      height: 35px;
      @media(min-width: 1280px){
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }
      color: #000;
      background: transparent;
      border: none;text-align: center;
      @media(max-width: 1279px){
        margin: 10px 15px;
      }
    }
    .owl-next{
      @media(min-width: 1280px){
        left: 100%;
      }


    }
    .owl-prev{
      @media(min-width: 1280px){
        right: 100%;
      }

    }

    @media(max-width: 767px){
      .owl-next,.owl-prev{
        position: absolute;
        width: 30px;
        font-size: 18px;
        line-height: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
        margin: 0!important;
        color: #000000;
        border: none;

        .fa{
          text-shadow: none;
        }
      }
      .owl-next{
        right: -5px;
      }
      .owl-prev{
        left: -5px;
      }
    }
  }
  .w66{
    width: 66.666%;
    padding-left: 10px;
    .h50{
      height: 50%;
      img{
        //height: 100%;
        //object-fit: cover;
      }
      @extend .flex-wrapper;
      &:first-child{
        padding-bottom: 10px;
      }
      &:last-child{
        padding-top: 10px;
      }
      @media(max-width: 767px){
        height: 100%;
        padding-bottom: 0!important;
        &.desc{
          display: none;
        }
      }
    }
    @media(max-width: 1279px){
      display: none;
    }
    @media(max-width: 767px){
      width: 50%;
    }
    @media(max-width: 600px){
      width: 100%;
      padding: 0!important;
    }
  }
  .text{

    background: #fff url("../img/comma.png") 95% 20px no-repeat;
    padding:35px 50px;
    //@media(max-width: 600px){
    //  padding: 20px 50px 20px 20px;
    //}

    height: 100%;


   @media(min-width: 1280px){
     width: 100%;
     position: absolute;
     top: 0;
     left: 0;
     height: auto;
     bottom: 10px;
     //opacity: 0;
   }
    @media(max-width: 1279px){

      width: 50%;
      height: 100%;
      float: right;
      background: #fff url("../img/comma.png") 95% 95% no-repeat;
      padding: 20px;
      background-size: 50px;//30%;

    }

    transition-duration: 0.3s;
    .name{
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 15px;
      @media(max-width: 1279px){
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: 0.78px;
        margin-bottom: 10px;
        color: #3acc8a;
      }
    }
    .descr{
      font-size: 24px;
      @media(max-width: 1279px){
        font-size: 14px;
        line-height: 20px;
      }
    }
    &.rel{
      position: relative;
    }
    &.abs{
      opacity: 0;
      &.active{
        opacity: 1;
      }
    }
  }
}
.testi{
  padding-top: 60px;
  .inner{
    padding: 0 15px;
  }

  @media(max-width: 767px){

  }
  &--inner{
    &__title{
      width: 30%;
      @media(max-width: 1280px){
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
      }
      @media(max-width: 767px){
        text-align: left;
      }
      h2{
        margin: 0;
        font-weight: 700;
        line-height: 1.13;
        font-size: 48px;
        @media(max-width: 767px){
          font-size: 36px;
          max-width: 280px;
        }
      }
    }
    &__slider{
      width: 70%;
      @media(max-width: 1280px){
        width: 100%;
      }
      .title{
        text-transform: uppercase;
        font-size: 18px;
        margin-bottom: 10px;
        display: block;
        overflow: hidden;
        letter-spacing: 1px;
        line-height: 23px;
        @media(max-width: 767px){
          font-size: 14px;
          a{
            display: none;
          }
        }
      }
      .name{
        font-size: 12px;
        margin-bottom: 20px;
        .fa{
          margin-right: 15px;
        }
        @media(max-width: 767px){
          margin-bottom: 10px;
        }
      }
      .text{
        font-size: 14px;
        line-height: 1.43;
        padding-left: 55px;
        padding-right: 55px;
      }

      .owl-item{
        padding: 0 10px;
        @media(max-width: 767px){
          padding: 0;
        }
        span{
          float: left;
        }
        a{
          float: right;
        }
        img{
          width: auto;
        }
      }

      .owl-nav{
        text-align: center;
      }
      .owl-next,.owl-prev{

        font-size: 40px;
        line-height: 35px;
        height: 35px;
        @media(min-width: 1280px){
          position: absolute;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
        }
        color: #000;
        background: transparent;
        border: none;text-align: center;
        @media(max-width: 1279px){
          margin: 10px 15px;
        }
      }
      .owl-next{
        @media(min-width: 1280px){
          left: 100%;
        }


      }
      .owl-prev{
        @media(min-width: 1280px){
          right: 100%;
        }

      }

      @media(max-width: 767px){
        .owl-next,.owl-prev{
          position: absolute;
          width: 30px;
          font-size: 18px;
          line-height: 24px;
          height: 30px;
          border-radius: 50%;
          background: #fff;
          top: 50%;
          transform: translateY(-50%);
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
          margin: 0;
          color: #000000;
          border: none;

          .fa{
            text-shadow: none;
          }
        }
        .owl-next{
          right: 5px;
        }
        .owl-prev{
          left: 5px;
        }
      }
    }
  }

}

.owl-prev,.owl-next{
  cursor: pointer;
}

.galleries{
  .inner{
    padding: 0 15px;
  }
  h2{
    margin: 0 0 30px;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.13;
    @media(max-width: 767px){
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  &--inner{
    position: relative;
  }
  &--tabs{
    list-style: none;
    overflow: hidden;
    margin: 10px 0 0 ;
    padding: 0;
    li{
      cursor: pointer;
      border-radius: 13px;
      background: #ebebeb;
      line-height: 26px;
      color: #000000;
      transition-duration: 0.3s;
      padding: 0 20px;
      float: left;
      margin-top: 10px;
      text-transform: uppercase;
      font-size: 12px;
      margin-right: 10px;
      &:hover{
        background: rgba(0,0,0,0.4);
        color: #ffffff;
      }
      &.active{
        background: #000;
        color: #ffffff;
      }
      @media(max-width: 767px){
        font-size: 10px;
        padding: 0 10px;
      }
    }
  }
  .gallery_btn{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: url("../img/enlarge.png");
    box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
    @media(max-width: 767px){
      width: 15px;
      height: 15px;
      background-size: cover;
    }
  }
  .owl-gallery{
    &.abs{
      position: absolute;
      left: 0;
      //visibility: hidden;
      top: 0;
      //z-index: -1;
     // opacity: 0;
      z-index: 0;
    }
    &.rel{
      position: relative;
      z-index: 1;

    }
    &.active{
      z-index: 2;
    }

    .owl-next,.owl-prev{
      position: absolute;
      font-size: 40px;
      line-height: 35px;
      height: 35px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      color: #ffffff;
      background: transparent;
      border: none;
      .fa{
        text-shadow: 3px 3px 10px rgba(0,0,0,0.5);
        //text-shadow: 3px 3px 10px #000;
      }
    }
    .owl-next{
      right: 20px;
    }
    .owl-prev{
      left: 20px;
    }

    @media(max-width: 767px){
      .owl-next,.owl-prev{
        position: absolute;
        width: 24px;
        font-size: 16px;
        line-height: 24px;
        height: 24px;
        border-radius: 50%;
        background: #fff;
        //top: 65px;
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);

        color: #000000;
        border: none;

        .fa{
          text-shadow: none;
        }
      }
      .owl-next{
        right: -5px;
      }
      .owl-prev{
        left: -5px;
      }
    }
  }
  .item{
    position: relative;
    .mark{
      position: absolute;
      z-index: 1;
      //padding-top: 10px;
      //padding-left: 10px;
      .icon{
        cursor: pointer;
        border-radius: 50%;
        background-color: rgb(58, 204, 138);
        box-shadow: 0px 4px 10px 0px rgba(58, 204, 138, 0.3);
        width: 38px;
        height: 38px;
        position: absolute;
        top: 0;
        left: 0;
        transition-duration: 0.3s;
        z-index: 2;
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: auto;
          right: 0;
          bottom: 0;
          margin: auto;
          display: block;
        }
      }
      .description{
        //visibility: hidden;
        opacity: 0;
        transition-duration: 0.3s;

        background: #fff;
        padding: 13px 40px;
        font-size: 12px;
        font-weight: 300;
        max-width: 180px;
        margin-top: 10px;
        margin-left: 10px;
        border-radius: 0 0 25px 0;
        top: 10px;
        left: 10px;
      }
      &:hover{
        .icon{
          transform: scale(1.1);
        }
      }
      &.left{
        .icon{
          position: relative;
        }
        .description{
          width: 180px;
          margin: 0;
          right: 10px;
          left: initial;
          position: absolute;
          border-radius: 0 0 0 25px;
        }
      }
      &.bottom{
        .description{
          position: absolute;
          bottom: 10px;
          width: 180px;

          z-index: 1;
          margin: 0;
          top: initial;
        }
        .icon{
          position: relative;
        }
      }
      &.active{
        .description{
          opacity: 1;
        }
        .icon{
          transform: scale(1.1);
        }
      }
      @media(max-width: 767px){
        display: none;
      }
    }
  }
}

.crumbs{
  padding: 10px 0;
  .inner{
    padding: 0 15px;
  }
  .breadcrumb{
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    li{
      font-weight: 700;
      float: left;
      padding: 0 25px;
      position: relative;
      font-size: 12px;
      a{
        display: block;
        font-weight: 300;
      }
      &:after{
        content: '\f105';
        font-family: FontAwesome;
        position: absolute;
        width: 6px;
        text-align: center;
        left: 100%;
        margin-left: -3px;
        top: 0;
        font-size: 9px;
        line-height: 13px;
      }
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
        &:after{
          display: none;
        }
      }
      @media (max-width: 767px) {
        display: none;
        &:first-child,&:last-child{
          display: block;
        }
      }
    }
  }
}

input,select{
  font-family: 'Rubik', sans-serif!important;
  font-size: 14px!important;
  border-radius: 0;
}


@media(min-width: 1366px){
  //section{
  //  &:not(.hero),
  //  &:not(.crumbs),
  //  &:not(.bestprice){
  //    padding-top: 110px!important;
  //    padding-bottom: 0!important;
  //  }
  //}

  footer{
  //  margin-top: 110px;
  }
  .about_hostel{
    margin-top: 110px;
  }

}
section:not(.crumbs,.hero,.bestprice,.static,.about_hostel,.about-tour-after,.testi,.tour-hero,.group-only,.promo-only,.adventures,.map_contacts,.promo--terms){
  @media(min-width: 768px){
    padding-top: 110px!important;
    padding-bottom: 0!important;
  }
  @media(max-width: 767px){
    padding-top: 60px!important;
    padding-bottom: 0!important;
  }
}



.nice-select{
  font-family: 'Rubik', sans-serif;
  width: 100%;
  border: solid 1px rgba(0,0,0,0.2);
  -webkit-background-clip: padding-box;
  border-radius: 0;
  //padding-left: 20px;
  transition-duration: 0.3s;
  height: 40px;
  line-height: 38px;
  padding: 0;
  font-size: 14px;
  .current{
    background: #fff url("../img/arrow-down@3x.png") 95% center no-repeat;
    background-size: 14px;
    z-index: 24;
    display: block;
    position: relative;
    padding-left: 20px;
    padding-right: 30px;
  }
  &:hover{
    border-color: #3acc8a;
  }

  &:after{
    display: none;
  }
  &:before{
    //content: '\f107';
    //font-family: FontAwesome;
    //position: absolute;
    //right: 20px;
    //height: 40px;
    //line-height: 40px;
    //top: 0;
    //z-index: 0;
    //font-size: 18px;
    //transition-duration: 0.3s;
  }

  .list{
    //bottom: 100%;
    bottom: 0;
    top: inherit;
    width: 100%;
    border-color: #fff;
    margin-top: 0;
    border-radius: 0;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    .option.focus, .option.selected.focus{
      &:not(.disabled){
        background-color: #fff;
        font-weight: 700;
        &:hover{
          background: #3acc8a;
        }
      }
    }
  }

  &:active, &.open{
    border-color: #fff;
  }

  &.open{
    //box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    &:before{
      transform: rotate(180deg);
    }
    .disabled{
      font-size: 12px;
      color: #3acc8a;
      font-weight: 400;
      &:hover{
        background: #fff;

      }

    }
    .current{
      visibility: hidden;
      opacity: 0;
      z-index: -1;
    }
  }

  .option{
    position: relative;
    overflow: hidden;
    &:after{
      width: 94px;
      height: 94px;
      background-color: #fff;
      position: absolute;
      top: -26px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      transition-duration: 0.3s;
      content: '';
      display: block;
      border-radius: 50%;
      opacity: 0;
    }
  }
  .option:hover{
    background: #3acc8a;
  }

  .option{
    &:active{
      background: #3acc8a;
      &:after{
        opacity: 0.4;
      }
    }
  }
}
h2,h1{
  img{
    max-height: 40px;
    margin-left: 20px;
    @media(max-width: 767px){
      height: 40px;
    }
  }
}

.adventures{
  h1{
    font-size: 48px;
    font-weight: bold;
    line-height: 1.13;
    color: #262626;
    margin: 30px 0;
  }
  &--item{
    position: relative;
    color: #000000;
    @media(max-width: 1023px){
      margin: 0 15px 20px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
      &:last-child{
        margin-bottom: 0px;
      }
    }
    &+.adventures--item{
      margin-top: 20px;
    }
    @media(min-width: 1024px){
      min-height: 396px;
    }
    &__content{
      position: relative;
      padding: 30px 50px 100px;
      @media(min-width: 1200px){
        height: 327px;
      }
      @media(min-width: 1024px){

        //min-height: 327px;
        padding-right: 500px;
      }
      @media(max-width: 1023px){
        background-color: #fff!important;
        border-radius: 0 0 30px;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
        order: 2;
        width: 100%;
        padding: 20px;
      }
    }
    &__img{
      position: absolute;
      right: 50px;
      bottom: 0;
      max-width: 400px;
      @media(min-width: 1024px){
        background: transparent!important;
      }
      @media(max-width: 1023px){
        width: 100%;
        height: 110px;
        position: relative;
        max-width: 100%;
        overflow: hidden;
        right: 0;
        order: 1;
        img{
          height: 200px;
          position: relative;
          margin: 0 auto;
          display: block;
          top: -50%;
        }
      }
    }
    &__title{
      line-height: 22px;
      font-size: 18px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    &__price{
      font-size: 14px;
      line-height: 1.43;
    }
    &__descr{
      font-size: 14px;
      line-height: 1.43;
      margin-top: 20px;
      p{
        margin: 0;
      }
      @media(max-width: 1023px){
        display: none;
      }
    }
    &__control{
      position: absolute;
      bottom: 30px;
      @media(max-width: 1023px){
        position: relative;
        bottom: initial;
      }
      @extend %clearfix;
      a,button{

        font-size: 12px;
        text-transform: uppercase;
        height: 48px;
        float: left;
        max-width: 180px;
        line-height: 14px;
        span{
          position: relative;
          line-height: 14px;
          letter-spacing: 1px;
          top: 50%;
          transform: translateY(-50%);
        }
        &.btn-white{
          font-weight: 700;
          height: 46px;
          line-height: 14px;
          margin-left: 30px;
          @media(max-width: 1023px){
            border: 2px solid #3acc8a;
            color: #3acc8a;
            &:before{
              content: url("../img/btn-arrow-green.png");
              position: absolute;
              right: 15px;
              display: block;
              top: 50%;
              transform: translateY(-50%);
              opacity: 0;
              transition-duration: 0.3s;
            }
            &:after{
              width: 94px;
              height: 94px;
              background-color: #3acc8a;
              position: absolute;
              top: -26px;
              left: 0;
              right: 0;
              margin-left: auto;
              margin-right: auto;
              transition-duration: 0.1s;
              content: '';
              display: block;
              border-radius: 50%;
              opacity: 0;
            }
            &:hover{
              //background: darken(#3acc8a,15%);
              &:before{
                opacity: 1;
              }
            }
            &:active{
              &:after{
                opacity: 0.4;
                width: 100%;
              }
            }
          }
          @media(max-width: 399px){
            padding: 0 20px;
          }
        }
      }
      a{
        line-height: 48px;
        text-decoration: underline;
        position: relative;

        &:before{
          transition-duration: 0.3s;
          left: -20px;
          right: -20px;
          border-radius: 24px;
          background: #3acc8a;
          opacity: 0;
          position: absolute;
          top: 0;
          content: '';
          height: 100%;
          display: block;
        }
        &:hover{
          &:before{
            opacity: 0.4;
          }
        }
        &:focus{
          &:before{
            opacity: 0.4;
            border-radius: 13px;
          }
        }
      }
    }
  }
}
