/*
* Third Party
*/
@import "source/normalize.css";
@import "source/datepicker.css";
@import "source/jquery.mCustomScrollbar.min.css";
@import "source/owl.carousel.min.css";
@import "source/nice-select.css";
@import "source/lightgallery.min.css";
@import "source/modal.css";
@import "font-awesome.min.css";


/*
* Custom
*/
@import "partials/mixins";

@import "partials/app";

@import "partials/header";
@import "partials/content";
@import "partials/footer";