.site_wrapper{
  padding-top: 90px;
  @media(max-width: 767px){
    padding-top: 60px;
  }

}

.header{
  .inner{
    padding: 0 15px;
    @extend %clearfix;
  }
  &--logo{
    float: left;
  }
  &--right{
    float: right;
    @extend %clearfix;
  }
  .nav__item{
    float: left;
  }
  .nav-wrapper{
    .inner{
      height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &--select{
    width: 280px;
    float: left;
    margin-left: 100px;
    .nice-select.open .list,
    .nice-select .list {
      top: 0;
      bottom: initial;
    }
    @media(max-width: 768px){
      display: none;
    }
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0;
  //color: #fff;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  transition-duration: .3s;
  background: #fff;
  @media(max-width: 767px){
    padding: 10px 0;
  }
  //@media #{$mobile} {
  //  justify-content: flex-end;
  //  height: 79px;
  //  padding: 0 0 0 198px;
  //  border-bottom: 1px solid #646974;
  //}

  .nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &__item:last-child {
      //@media #{$mobile} {
      //  display: flex;
      //  align-items: center;
      //  justify-content: center;
      //  width: 80px;
      //  height: 100%;
      //}
    }
  }
  .lang {
    margin-top: 10px;
    margin-right: 40px;
    padding: 23px 0 0 0;
    height: 0;
    position: relative;
    //@extend %icon-chevron;
    overflow: hidden;
    background: #ebebeb;
    color: #000000;
    padding-left: 10px;
    padding-right: 34px;
    border-radius: 25px;
  //font-family: $dinot;

    &:after {
      position: absolute;
      right: 7px;
      top: 0;
      height: 23px;
      line-height: 23px;
      font-size: 13px;
      content: '\f107' ;
      font-family: FontAwesome;
    }
    &.open {
      overflow: visible;
      //background: rgba(0,0,0,0.4);
      background: rgba(0,0,0,1);
      border-radius: 15px 15px 0 0 ;
      &:before {
        transform: scaleY(-1);
      }
      li{
        color: #ffffff!important;
        background: rgba(0,0,0,1)!important;
      }
      //background-color: #fff;
      //color: #0a0934;
      li{
        text-align: left;
        &.current-menu-item{
          color: #0a0934;
        }
      }
    }
    li {
      display: block;
      font-weight: 300;
      font-size: 12px;
      padding: 0;
      cursor: pointer;
      &.active {
        //padding: 0 25px 0 5px;
        position: absolute;
        top: 0;
        line-height: 23px;

        //background: #ebebeb;
        //padding-left: 10px;
        //padding-right: 34px;
      }
      &:not(.active) {
        line-height: 24px;
        width: 100%;
        background: rgba(0,0,0,0.4);
        //background-color: #fff;
        color: #000;
        padding: 0;

        position: absolute;
        left: 0;
        top: 100%;
        line-height: 23px;
        border-radius: 0 0 15px 15px;
        padding: 0 10px;

        //&:last-child{
        //  position: relative;
        //  &:after{
        //    background: #fff;
        //    height: 7px;
        //    top:100%;
        //    width: 100%;
        //    left: 0;
        //    display: block;
        //    content: '';
        //    position: absolute;
        //  }
        //}
        &:hover {
          color: #fff;
          background: rgba(0,0,0,1);
          //background-color: $yellow;
        }

        a{
          padding: 0 25px 0 5px;
          display: block;
        }
      }
      &:nth-child(2){
        border-radius: 0;
      }
      &:nth-child(3){
        top: calc(100% + 23px);
        border-radius: 0;
      }
      &:nth-child(4){
        top: calc(100% + 46px);
      }
      //@for $i from 3 through 4 {
      //    &:nth-child(#{$i}) {
      //      top: calc(100% + (#{$i}-2)*23px);
      //      &:before {
      //        content: '#{$i}';
      //      }
      //    }
      //
      //}
    }
    @media #{$tablet} {
      margin-top: 0;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
    }
    @media #{$mobile} {
      right: 43px;
    }
  }
}

.nav-wrapper {
  visibility: hidden;
  transition-duration: .3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 8;
  transform: translate3d(0,0,0);
  background: #fff;
  //@media #{$tablet} {
  //  padding: 30px 0;
  //  width: 100%;
  //  background: #fff;
  //}
  //@media #{$mobile} {
  //  top: 79px;
  //  height: calc(100% - 79px);
  //  padding: 0 0 12px;
  //}

  &__bg {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    transition-duration: .3s;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    //@media #{$tablet} {
    //  content: none;
    //}
  }
  .table-wrapper{
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
  nav {
    background-color: #fff;
    //padding: 40px 170px;
    //padding: 40px 20px;
    padding: 100px 20px 40px;

    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
   // width: 734px;
    //width: 734px;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;

    color: #000;
    font-size: 24px;
    text-transform: uppercase;
    //position: absolute;
    text-align: left;
    top: 0;
    bottom: 0;
    right: 0;
    transition-duration: .3s;
    transform: translateX(100%);
    overflow: auto;
    @extend %clearfix;
    .w50{
      //width: 50%;
      &:first-child{
        width: 50%;
        padding-left: 10%;
      }
      &:last-child{
        width: 30%;
      }
      &:first-child{
        text-align: center;
        ul{
          text-align: left;

        }
      }
      //float: left;
      @media(max-width: 767px){
        &:first-child{
          width: 100%;
          padding-left: 0;
        }
        &:last-child{
          width: 40%;
        }
      }
    }

    .menu-item-has-children{
      .sub-menu{
        transition-duration: 0.3s;
        visibility: hidden;
        overflow: hidden;
        z-index: -1;
        @media(max-width: 767px){
          display: none;
        }
        @media(min-width: 768px){
          height: auto!important;
          position: absolute;
          left: 100%;
          top: 0;
          width: 100%;
          transform: translateX(100px);
          li{
            float: left;
            width: 50%;
            padding: 0 10px;
          }
        }

      }
      &.open{
        &>a{
          color: #3acc8a;
        }
        .sub-menu{
          opacity: 1;
          visibility: visible;
          z-index: 1;
          @media(max-width: 767px){
            display: block;
            font-size: 14px;
            width: 100%;
            border-top: 1px solid darkgray;
            li{
              text-transform: uppercase;
              margin-top: 10px;
              &+li{
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
    @media(max-width: 560px){
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (min-width: 768px) and (max-height: 768px) {
      font-size: 22px;
    }
    @media (min-width: 768px) and (max-height: 650px) {
      font-size: 20px;
    }
    @media (min-width: 768px) and (max-height: 525px) {
      font-size: 18px;
    }
    //@media #{$tablet} {
    //  width: 100%;
    //  background-color: transparent;
    //  font-size: 20px;
    //  text-align: center;
    //}
    //@media #{$mobile} {
    //  position: static;
    //  padding: 0;
    //  text-align: left;
    //  overflow: hidden;
    //}
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    //@media #{$mobile} {
    //  position: relative;
    //  height: calc(100vh - 79px);
    //  overflow-x: auto;
    //}
  }
  .open,
  .current-menu-item {
    > a {
      //color: $yellow;
      font-weight: 700;
    }
  }
  li {
    //display: block;
    //margin-bottom: 30px;
    //transition-duration: .3s;
    //transition-delay: 0s;
    //opacity: 0;
    //transform: translateX(50px);
    //@media (min-width: 768px) and (max-height: 768px) {
    //  margin-bottom: 20px;
    //}
    //@media (min-width: 768px) and (max-height: 650px) {
    //  margin-bottom: 15px;
    //}
    //&:last-child {
    //  margin: 0;
    //}
    //@for $i from 1 through 11 {
    //  &:nth-child(#{$i}) {
    //    transition-delay: .2s + $i/11;
    //  }
    //}
    //@media #{$tablet} {
    //  margin: 0 0 10px;
    //}
    //@media #{$mobile} {
    //  margin: 0;
    //  border-bottom: 1px solid #dcdcdc;
    //  &:last-child {
    //    border: 0;
    //  }
    //}
    //a {
    //  @media #{$tablet} {
    //    display: block;
    //    padding: 10px;
    //  }
    //  @media #{$mobile} {
    //    padding: 10px 22px;
    //    font-size: 14px;
    //  }
    //}
    ul {
      font-size: 16px;
      font-weight: 400;
      transition-duration: .3s;
      overflow: hidden;
      //li {
      //  &:first-child {
      //    margin-top: 20px;
      //    @media #{$tablet} {
      //      margin-top: 10px;
      //    }
      //  }
      //}
      @media #{$tablet} {
        font-size: 12px;
      }
    }
    &.empty {
      height: 20px;
      @media #{$tablet} {
        & + li {
          background-color: #dddddd;
        }
      }
    }
    &:not(.open) {
      > ul {
        ///height: 0 !important;
      }
    }
  }
  a {
    &:hover {
      //color: $yellow;
    }
  }
}

.toggle__menu,
.close-sticky {
  background-color: transparent;
  border: none;
  width: 24px;
  height: 16px;
  cursor: pointer;
  position: relative;
  z-index: 9;
  padding: 42px 0 0;
  //@media #{$mobile} {
  //  width: 80px;
  //  height: 79px;
  //}
  //@media #{$sm} {
  //  height: 70px;
  //}
  &:focus {
    outline: none!important;
  }
  .wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 24px;
    height: 16px;
    margin: auto;
    @media #{$mobile} {
      transform: scale(1.37);
    }
  }
  .line {
    width: 24px;
    height: 3px;
    position: absolute;
    background-color: #000000;
    right: 0;
    display: block;
    &:nth-child(1) {
      top: 0;
      transition: top .15s .15s linear, background .3s 0s linear, transform .15s 0s linear;
    }
    &:nth-child(2) {
      top: 7px;
      opacity: 1;
      transition: opacity 0s .15s linear, background .3s 0s linear;
    }
    &:nth-child(3) {
      top: 14px;
      transition: top .15s .15s linear, background .3s 0s linear, transform .15s 0s linear;
    }
  }
}


.navbar-open {
  .toggle__menu {
    //position: fixed;
    //top: 40px;
    //right: 40px;
    //@media #{$mobile} {
    //  position: relative;
    //  top: auto;
    //  right: auto;
    //}
    .line {
      background-color: #1a1a1a;
      height: 3px;
      //@media #{$mobile} {
      //  height: 2px;
      //  background: #fff;
      //}
      &:nth-child(1) {
        top: 8px;
        transition: top .15s 0s linear, background .3s 0s linear, transform .15s .15s linear;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        //opacity: 0;
        opacity: 1;
        transform: translateY(15px);
      }
      &:nth-child(3) {
        top: 8px;
        transition: top .15s 0s linear, background .3s 0s linear, transform .15s .15s linear;
        transform: rotate(-45deg);
      }
    }
  }
  .nav-wrapper {
    visibility: visible;
    opacity: 1;
    &__bg {
      width: calc(100% - 734px);
      //@media #{$tablet} {
      //  display: none;
      //}
    }
    nav {
      transform: translateX(0);
    }
    li {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .lang {
    display: none;
    //@media #{$mobile} {
    //  display: block;
    //}
  }
}
.sticky{
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);
  z-index: 999;
  padding: 10px 0;
}

.main_menu{
  padding-top: 50px;
  font-size: 48px;
  font-weight: 700;
  li{
    text-transform: none;
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
    @media(max-width: 560px){
      margin-bottom: 10px;
    }
  }
  @media(max-width: 767px){
    font-size: 36px;
  }
  @media(max-width: 410px){
    font-size: 33px;
  }
}
.menu_towns{
  border-left: 2px solid #c3c3c3;
  li{
    padding-left: 20px;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
    @media(max-width: 560px){
      margin-bottom: 10px;
    }
    @media(max-width: 410px){
      font-size: 12px;
    }
  }
}

